export const state = () => ({
  auth: false,
  role: `guest`,
  user: null
})

export const mutations = {
  SET_AUTH (state, payload) {
    state.auth = payload
  },
  SET_ROLE (state, payload) {
    state.role = payload
  },
  SET_USER_INFO (state, payload) {
    state.user = payload
  },
  SET_RESET_STATE_USER (state) {
    Object.assign(state, {
      auth: false,
      role: `guest`,
      user: null
    })
  }
}

export const actions = {}

export const getters = {
  getRole: state => state.role,
  getUser: state => state.user,
  getIsAuth: state => state.auth,
  getStatus: state => state.status
}
