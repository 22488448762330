var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    { attrs: { value: _vm.show, "max-width": "360", persistent: "" } },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "text-h6" }, [
            _vm._v("Sin conexión"),
          ]),
          _vm._v(" "),
          _c("v-card-text", [
            _vm._v(
              "Se ha perdido la conexión con el servidor. Espere un momento o intente recargar la página."
            ),
          ]),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.reload()
                    },
                  },
                },
                [_vm._v(" Recargar ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }