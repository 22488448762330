<template>
  <v-list shaped>
    <v-list-group>
      <template v-slot:activator name="principal">
        <v-list-item-icon>
          <v-icon v-text="icon" size="20" :color="colorIcon"/>
        </v-list-item-icon>
        <v-list-item-title class="caption" v-text="title" />
      </template>

      <div v-for="(item, index) in items" :key="index" name="sub-principal">
        <template v-if="item.items" name="condicional">
          <v-list-group no-action sub-group>
            <template v-slot:activator name="activator-list-group">
              <v-list-item-content>
                <v-list-item-title class="caption" v-text="item.title" />
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon size="20" v-text="item.icon" :color="item.colorIcon" />
              </v-list-item-icon>
            </template>
            <v-list-item
              v-for="(subItem, subIndex) in item.items"
              :key="subIndex"
              :to="subItem.to"
              :target="subItem.target"
              exact
            >
              <v-list-item-icon>
                <v-icon size="20" v-text="subItem.icon" :color="item.colorIcon" />
              </v-list-item-icon>
              <v-list-item-title
                class="caption"
                v-text="subItem.title"
              ></v-list-item-title>
            </v-list-item>
          </v-list-group>
        </template>
        <v-list-group v-else no-action sub-group prepend-icon="">
          <template v-slot:activator>
            <v-list-item exact :to="item.to" class="pl-0" :target="item.target">
              <v-list-item-icon>
                <v-icon size="20" v-text="item.icon" :color="item.colorIcon" />
              </v-list-item-icon>
              <v-list-item-title
                class="caption"
                v-text="item.title"
              ></v-list-item-title>
            </v-list-item>
          </template>
        </v-list-group>
      </div>
    </v-list-group>
  </v-list>
</template>

<script>
export default {
  name: `MenuSubItems`,
  props: [`items`, `title`, `icon`, , `colorIcon`]
}
</script>

<style>
</style>
