const moment = require(`moment`)

export const state = () => ({
  url: null,
  dates: {
    startDate: moment().format(`YYYY-MM-DD`),
    endDate: moment().format(`YYYY-MM-DD`)
  },
  option: null,
  listFilterByDate: [
    {
      text: `Por hora`,
      value: `hourFilter`
    },
    {
      text: `Por día`,
      value: `dayFilter`
    },
    {
      text: `Por mes`,
      value: `monthFilter`
    }
  ]
})
  
/**
 * Tiene la funcionalidad de modificar los estado, ya que VUEX no permite modificar directamente los estados
 * @param {*} state hace referencia a las propiedades declaradas en el estado
 * @param {*} payload hace referencia al valor enviado mediante los commits para que dicho valor modifique a la propiedad.
 */
export const mutations = {
  SET_DATES (state, payload) {
    state.dates = payload
  },
  SET_OPTION (state, payload) {
    state.option = payload.option
  }
}

export const getters = {
  getOption: state => state.option,
  getListFilterByDate: state => state.listFilterByDate
}
