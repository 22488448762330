export default function({ redirect, route, store }) {
  const { state } = store
  const { auth } = state
  const { auth: logged, role, user } = auth

  const urlAdmin = [
    `/admin/dashboard/calls/consolidate`,
    `/admin/dashboard/calls/realtime`,
    `/admin/dashboard/chats/consolidate`,
    `/admin/dashboard/chats/realtime`,
    `/admin/dashboard/consolidate-general`,
    `/admin/dashboard/survey-nps`,
    `/admin/dashboard/survey/survey-cap`,
    `/admin/dashboard/power-bi`,
    `/admin/dashboard/reports/reportFrame`,
    `/admin`
  ]

  if (logged) {
    if (role === `supervisor` && user) {
      const result = urlAdmin.find(url => url === route.path)
      if (!result) {
        return redirect(`/admin`)
      }
    }
  } else {
    return redirect(`/`)
  }
}
