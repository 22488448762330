/**
 * Tienda de datos para el servidor temporal. Cuando se necesite hacer referencia al tiempo del backend, utilizar este estado
 */


export const state = () => ({time: ``})
  

export const mutations = {
  SET_TIME (state, payload) {
    state.time = payload
  }
}
  

export const getters = {getTime: state => state.time}
  
  
  
