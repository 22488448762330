<template>
  <v-dialog :value="show" max-width="360" persistent>
    <v-card>
      <v-card-title class="text-h6">Sin conexión</v-card-title>
      <v-card-text
        >Se ha perdido la conexión con el servidor. Espere un momento o intente recargar la página.</v-card-text
      >
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="reload()"> Recargar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  computed: {
    show () {
      return this.$store.state.socket.showErrorConnection
    }
  },
  methods: {
    reload () {
      location.reload()
    }
  }
}
</script>

<style>
</style>
