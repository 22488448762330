export const strict = false

export const actions= {
  nuxtServerInit ({ commit }) {
    const isAuth = this.$cookies.get(`auth`)
    const user = this.$cookies.get(`user`)

    if (isAuth) {
      if (user) {
        commit(`auth/SET_AUTH`, isAuth)
        commit(`auth/SET_ROLE`, user.role)
        commit(`auth/SET_USER_INFO`, user)
      }
    } else console.info(`no está logeado`)
  }
}
