<template>
  <v-app-bar app clipped-left color="white">
    <span class="mr-5">
      <v-img src="/media/logo.png" />
    </span>
    <v-btn icon light @click="primaryDrawer = !primaryDrawer" class="ml-3">
      <v-tooltip bottom v-if="primaryDrawer" class="color-primary">
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" size="20">fas fa-bars</v-icon>
        </template>
        <span>{{ $t("collapse") }}</span>
      </v-tooltip>
      <v-tooltip bottom v-else class="color-primary">
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" size="20">fas fa-bars</v-icon>
        </template>
        <span>{{ $t("open") }}</span>
      </v-tooltip>
    </v-btn>
    <v-btn icon light @click.stop="toggleMiniVariantMode">
      <v-tooltip bottom v-if="primaryDrawerMini" class="color-primary">
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" size="20">fas fa-angle-right</v-icon>
        </template>
        <span>{{ $t("extend") }}</span>
      </v-tooltip>
      <v-tooltip bottom v-else class="color-primary">
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" size="20">fas fa-angle-left</v-icon>
        </template>
        <span>Mini</span>
      </v-tooltip>
    </v-btn>
    <v-spacer></v-spacer>
    <v-toolbar-items>
      <v-menu left bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-avatar size="30" color="transparent">
              <v-img :src="imgSrc" />
            </v-avatar>
          </v-btn>
        </template>
        <v-card class="mx-auto" max-width="300" tile>
          <v-list rounded>
            <v-list-item>
              <v-list-item-avatar>
                <v-img :src="imgSrc"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ user && user.fullName }}</v-list-item-title>
                <v-list-item-subtitle>Supervisor Apropo</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list>
            <v-list-item @click="logout">
              <v-list-item-avatar>
                <v-icon size="22">fas fa-power-off</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Logout</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
import MixinHeader from "~/mixins/header"
import { mapGetters, mapActions } from "vuex"
const Swal = require(`sweetalert2`)

export default {
  name: `header-admin`,
  mixins: [MixinHeader],
  computed: {
    ...mapGetters({ user: `auth/getUser` }),
    imgSrc () {
      return `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSsgLMiDAw6mY801rwwaC3DTjjIDDaOEPEJ7Q&usqp=CAU`
    }
  },
  mounted () {
    this.onEvent({ eventName: `out:logout_admin`, callback: response => this.logoutDetail(response) })
  },
  methods: {
    ...mapActions({
      emitEvent: `socket/emitEvent`,
      onEvent: `socket/onEvent`
    }),
    toggleMiniVariantMode () {
      this.$store.dispatch(`header/toggleMiniVarient`)
      this.$store.dispatch(`header/toggleMiniVariantMode`)
    },
    logout () {
      const user = this.$cookies.get(`user`)
      this.emitEvent({ eventName: `in:logout_admin`, params: { id: user.id } })
    },
    // Función que procesa la información emitida por el servidor tras realizar el evento "logout"
    logoutDetail (response) {
      const { success, data, error } = response
      if (success) {
        // Resetear valores referentes a la autenticación del usuario
        this.$cookies.removeAll()
        this.$store.commit(`auth/SET_ROLE`, `guest`)
        this.$store.commit(`auth/SET_RESET_STATE_USER`)
        this.$store.commit(`auth/SET_AUTH`, false)

        this.$router.push({ path: `/` })
      } else {
        const { message, textCode } = error
        Swal.fire({
          icon: textCode,
          text: message,
          timer: 3000,
          timerProgressBar: true
        })
      }
    }
  }
}
</script>
<style scoped>
.top-menu-header {
  top: 70px !important;
}
.fontsize-22 {
  font-size: 22px !important;
}
</style>
