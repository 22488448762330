<template>
  <v-list shaped>
    <v-list-item
      v-for="item in items"
      :key="item.title"
      :to="item.to"
      exact
      :target="item.target"
    >
      <v-list-item-icon>
        <v-icon v-text="item.icon" size="20" :color="item.colorIcon" />
      </v-list-item-icon>
      <v-list-item-title class="caption" v-text="item.title" />
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: `MenuItems`,
  props: [`items`]
}
</script>

<style>
</style>
