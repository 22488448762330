var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { attrs: { id: "inspire" } },
    [
      _c("HeaderAdmin"),
      _vm._v(" "),
      _c("DrawerAdmin"),
      _vm._v(" "),
      _c("v-main", [_c("div", [_c("nuxt")], 1)]),
      _vm._v(" "),
      _c("Loading", { attrs: { options: _vm.optDialogLoading } }),
      _vm._v(" "),
      _c("SocketConnectionError"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }