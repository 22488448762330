import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _523c031c = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _e542f99c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _c1229e6e = () => interopDefault(import('../pages/admin/dashboard/consolidate-general.vue' /* webpackChunkName: "pages/admin/dashboard/consolidate-general" */))
const _c278248c = () => interopDefault(import('../pages/admin/dashboard/power-bi.vue' /* webpackChunkName: "pages/admin/dashboard/power-bi" */))
const _eaa1852e = () => interopDefault(import('../pages/admin/dashboard/survey-nps.vue' /* webpackChunkName: "pages/admin/dashboard/survey-nps" */))
const _9e6cb398 = () => interopDefault(import('../pages/admin/dashboard/calls/consolidate.vue' /* webpackChunkName: "pages/admin/dashboard/calls/consolidate" */))
const _256c82d0 = () => interopDefault(import('../pages/admin/dashboard/calls/realtime.vue' /* webpackChunkName: "pages/admin/dashboard/calls/realtime" */))
const _5473607a = () => interopDefault(import('../pages/admin/dashboard/chats/consolidate.vue' /* webpackChunkName: "pages/admin/dashboard/chats/consolidate" */))
const _77a85e4a = () => interopDefault(import('../pages/admin/dashboard/chats/realtime.vue' /* webpackChunkName: "pages/admin/dashboard/chats/realtime" */))
const _e7184024 = () => interopDefault(import('../pages/admin/dashboard/reports/reportFrame.vue' /* webpackChunkName: "pages/admin/dashboard/reports/reportFrame" */))
const _43787219 = () => interopDefault(import('../pages/admin/dashboard/survey/survey-cap.vue' /* webpackChunkName: "pages/admin/dashboard/survey/survey-cap" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _523c031c,
    name: "admin___es"
  }, {
    path: "/en",
    component: _e542f99c,
    name: "index___en"
  }, {
    path: "/en/admin",
    component: _523c031c,
    name: "admin___en"
  }, {
    path: "/admin/dashboard/consolidate-general",
    component: _c1229e6e,
    name: "admin-dashboard-consolidate-general___es"
  }, {
    path: "/admin/dashboard/power-bi",
    component: _c278248c,
    name: "admin-dashboard-power-bi___es"
  }, {
    path: "/admin/dashboard/survey-nps",
    component: _eaa1852e,
    name: "admin-dashboard-survey-nps___es"
  }, {
    path: "/admin/dashboard/calls/consolidate",
    component: _9e6cb398,
    name: "admin-dashboard-calls-consolidate___es"
  }, {
    path: "/admin/dashboard/calls/realtime",
    component: _256c82d0,
    name: "admin-dashboard-calls-realtime___es"
  }, {
    path: "/admin/dashboard/chats/consolidate",
    component: _5473607a,
    name: "admin-dashboard-chats-consolidate___es"
  }, {
    path: "/admin/dashboard/chats/realtime",
    component: _77a85e4a,
    name: "admin-dashboard-chats-realtime___es"
  }, {
    path: "/admin/dashboard/reports/reportFrame",
    component: _e7184024,
    name: "admin-dashboard-reports-reportFrame___es"
  }, {
    path: "/admin/dashboard/survey/survey-cap",
    component: _43787219,
    name: "admin-dashboard-survey-survey-cap___es"
  }, {
    path: "/en/admin/dashboard/consolidate-general",
    component: _c1229e6e,
    name: "admin-dashboard-consolidate-general___en"
  }, {
    path: "/en/admin/dashboard/power-bi",
    component: _c278248c,
    name: "admin-dashboard-power-bi___en"
  }, {
    path: "/en/admin/dashboard/survey-nps",
    component: _eaa1852e,
    name: "admin-dashboard-survey-nps___en"
  }, {
    path: "/en/admin/dashboard/calls/consolidate",
    component: _9e6cb398,
    name: "admin-dashboard-calls-consolidate___en"
  }, {
    path: "/en/admin/dashboard/calls/realtime",
    component: _256c82d0,
    name: "admin-dashboard-calls-realtime___en"
  }, {
    path: "/en/admin/dashboard/chats/consolidate",
    component: _5473607a,
    name: "admin-dashboard-chats-consolidate___en"
  }, {
    path: "/en/admin/dashboard/chats/realtime",
    component: _77a85e4a,
    name: "admin-dashboard-chats-realtime___en"
  }, {
    path: "/en/admin/dashboard/reports/reportFrame",
    component: _e7184024,
    name: "admin-dashboard-reports-reportFrame___en"
  }, {
    path: "/en/admin/dashboard/survey/survey-cap",
    component: _43787219,
    name: "admin-dashboard-survey-survey-cap___en"
  }, {
    path: "/",
    component: _e542f99c,
    name: "index___es"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
