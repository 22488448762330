export default function({ redirect, store }) {
  const { state } = store
  const { auth } = state
  const { auth: logged, role } = auth

  if (logged) {
    if (role === `supervisor`) {
      return redirect(`/admin`)
    } else {
      return redirect(`/`)
    }
  } else console.info(`no está logeado`)
}
