export const state = () => ({
    graphics: {
      items: [],
      options: {
        chart: {
          width: 380,
          type: `pie`
        },
        colors: [`#da1315`, `#febd15`, `#09a70c`],
        labels: [``, ``, ``],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {width: 200},
            legend: {position: `right`}
          }
        }]
      }
    },
    channel: [`1`, `2`, `3`, `4`]
  })

  export const mutations = {
    SET_CHANNEL_GRAPHIC_NPS (state, payload) {
      state.channel = payload
    }
  }

  export const actions = {
    getDataTotalSurveyCapSd ({ state, dispatch, rootState }, params) {
     
      const { startDate, endDate } = rootState.filters.dates
      const channel = state.channel.length > 0 ? state.channel : [`1`, `2`, `3`, `4`]
      
      params = { ...params, startDate, endDate, channel }
      console.log('recuperando los parametros')
      console.log(params)
      dispatch(`socket/emitEvent`, { eventName: `in:total_survey_send`, params }, { root: true })
    },

    getDataTotalSurveySocioDemograficaResponse ({ state, dispatch, rootState }, params) {
     
      const { startDate, endDate } = rootState.filters.dates
      const channel = state.channel.length > 0 ? state.channel : [`1`, `2`, `3`, `4`]
      
      params = { ...params, startDate, endDate, channel }
    
      dispatch(`socket/emitEvent`, { eventName: `in:total_survey_sociodemografica_response`, params }, { root: true })
    },

  }