export const state = () => ({
  toolbarColor: `white`,
  colorPrimary: process.env.VUE_APP_color_project_primary,
  sidebarBackgroundColor: process.env.VUE_APP_color_project_secondary
})

export const mutations = {
  SET_TOOLBAR_COLOR (state, payload) {
    state.toolbarColor = payload.toolbarColor
  }
}



