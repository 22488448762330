<template>
  <!-- v-model="primaryDrawer"
    :mini-variant="primaryDrawerMini" -->
  <v-navigation-drawer id="app-drawer" app overflow width="256" clipped>
    <template v-slot:prepend>
      <v-list-item two-line class="mt-4 mb-4">
        <v-list-item-avatar>
          <v-img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSsgLMiDAw6mY801rwwaC3DTjjIDDaOEPEJ7Q&usqp=CAU"
          />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{ user && user.fullName }}</v-list-item-title>
          <v-list-item-subtitle>Supervisor Apropo</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
    <simplebar class="height-100" data-simplebar-auto-hide="true">
      <v-divider></v-divider>
      <MenuItems :items="itemsHome" />
      <MenuSubItems
        :items="itemsCalls"
        :title="$t(`drawerAdmin.dashboards.calls.title`)"
        icon="fas fa-phone-volume"
        colorIcon="rgb(240, 56, 0)"
      />
      <MenuSubItems
        :items="itemsChats"
        :title="$t(`drawerAdmin.dashboards.chats.title`)"
        icon="fas fa-comment-dots"
        colorIcon="#F3705A"
      />
    </simplebar>
  </v-navigation-drawer>
</template>
<script>
import MenuSubItems from "~/components/navigation/MenuSubItems"
import MenuItems from "~/components/navigation/MenuItems"
import MixinHeader from "~/mixins/header"
import "simplebar/dist/simplebar.min.css"
import simplebar from "simplebar-vue"
import Utils from "~/mixins/utils"
import { mapGetters } from "vuex"

export default {
  name: `drawer-admin`,
  components: {
    MenuSubItems,
    MenuItems,
    simplebar
  },
  mixins: [MixinHeader, Utils],
  data: vm => ({
    miniVariant: false,
    selectedItem: 0,
    itemsHome: [
      {
        title: vm.$t(`drawerAdmin.main.mainLink`),
        icon: `fas fa-home`,
        colorIcon: `blue`,
        to: `/admin`
      },
      {
        title: vm.$t(`drawerAdmin.dashboards.surveyNps.title`),
        icon: `fas fa-poll-h`,
        colorIcon: `orange`,
        to: `/admin/dashboard/survey-nps`,
        target: `_blank`
      },
      {
        title: vm.$t(`drawerAdmin.dashboards.surveyCapSociodemografica.title`),
        icon: `fas fa-poll-h`,
        colorIcon: `orange`,
        to: `/admin/dashboard/survey/survey-cap`,
        target: `_blank`
      },
      {
        title: vm.$t(`drawerAdmin.dashboards.consolidateGeneral.title`),
        icon: `fas fa-columns`,
        colorIcon: `green`,
        to: `/admin/dashboard/consolidate-general`,
        target: `_blank`
      },
      {
        title: `Reportes Power BI`,
        icon: `fas fa-columns`,
        colorIcon: `green`,
        to: `/admin/dashboard/power-bi`,
        target: `_blank`
      },
    ],
    itemsCalls: [
      {
        title: vm.$t(`drawerAdmin.dashboards.calls.consolidated`),
        icon: `fas fa-columns`,
        colorIcon: `rgb(72, 200, 239)`,
        to: `/admin/dashboard/calls/consolidate`,
        target: `_blank`
      },
      {
        title: vm.$t(`drawerAdmin.dashboards.calls.details`),
        icon: `mdi-monitor-dashboard `,
        colorIcon: `#FFD15C`,
        to: `/admin/dashboard/calls/realtime`,
        target: `_blank`
      }
    ],
    itemsChats: [
      {
        title: vm.$t(`drawerAdmin.dashboards.chats.consolidated`),
        icon: `fas fa-columns`,
        colorIcon: `rgb(72, 200, 239)`,
        to: `/admin/dashboard/chats/consolidate`,
        target: `_blank`
      },
      {
        title: vm.$t(`drawerAdmin.dashboards.chats.details`),
        icon: `mdi-monitor-dashboard `,
        colorIcon: `#FFD15C`,
        to: `/admin/dashboard/chats/realtime`,
        target: `_blank`
      }
    ]
  }),
  computed: {...mapGetters({user: `auth/getUser`})},
  mounted () {
    const ps = document.getElementById(`app-drawer`)
    ps.addEventListener(`mouseenter`, this.miniEnterVariantHandler)
    ps.addEventListener(`mouseleave`, this.miniLeaveVariantHandler)
  },
  beforeDestroy () {
    const ps = document.getElementById(`app-drawer`)
    if (ps) {
      ps.removeEventListener(`mouseenter`, this.miniEnterVariantHandler)
      ps.removeEventListener(`mouseleave`, this.miniVariantHandler)
    }
  },
  methods: {
    miniEnterVariantHandler () {
      if (
        this.primaryDrawerMiniMode &&
        this.primaryDrawerMini &&
        !this.$vuetify.breakpoint.mdAndDown
      ) {
        this.$store.dispatch(`header/toggleMiniVarient`, false)
      }
    },
    miniLeaveVariantHandler () {
      if (
        this.primaryDrawerMiniMode &&
        !this.primaryDrawerMini &&
        !this.$vuetify.breakpoint.mdAndDown
      ) {
        this.$store.dispatch(`header/toggleMiniVarient`, true)
      }
    }
  }
}
</script>
<style>
.toolbar-nav > div {
  padding: 0px !important;
}
.border-ra5 > a {
  border-radius: 5px;
}
.height-100 {
  height: 100%;
}
.padd-bottom-100 {
  padding-bottom: 100px;
}
.size_icon {
  width: 3px;
  height: 3px;
}
.text-size {
  font-size: 11px;
}
.content-logo {
  display: block;
}
</style>
