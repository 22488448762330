// Disabled eslint for this file because it is not used in the project
/* eslint-disable */

const { get } = require(`~/lib/sendRequest`)

export const state = () => ({
  reports: [],
  report: {}
})

export const mutations = {
  SET_REPORTS_POWER_BI(state, payload) {
    state.reports = payload
  },
  SET_REPORT_POWER_BI(state, payload) {

    state.report = { ...payload }
  }
}

export const actions = {
  async getReportsPowerBi({ commit, rootState }, reportParams) {
    const api = rootState.environment.apiServiceDataBase;
   let url = `power-bi-reports`;
    if (!api.toString().endsWith("/")) {
      url = `/${url}`;
    }
    const params = { ...reportParams };

    try {
      const { data } = await get({ api, url, params });

      commit('SET_REPORTS_POWER_BI', data.items);
    } catch (error) {
      console.error('Failed to fetch reports', error);
    }
  }
}
