var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list",
    { attrs: { shaped: "" } },
    [
      _c(
        "v-list-group",
        {
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function () {
                return [
                  _c(
                    "v-list-item-icon",
                    [
                      _c("v-icon", {
                        attrs: { size: "20", color: _vm.colorIcon },
                        domProps: { textContent: _vm._s(_vm.icon) },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-list-item-title", {
                    staticClass: "caption",
                    domProps: { textContent: _vm._s(_vm.title) },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _vm._l(_vm.items, function (item, index) {
            return _c(
              "div",
              { key: index, attrs: { name: "sub-principal" } },
              [
                item.items
                  ? [
                      _c(
                        "v-list-group",
                        {
                          attrs: { "no-action": "", "sub-group": "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function () {
                                  return [
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", {
                                          staticClass: "caption",
                                          domProps: {
                                            textContent: _vm._s(item.title),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-list-item-icon",
                                      [
                                        _c("v-icon", {
                                          attrs: {
                                            size: "20",
                                            color: item.colorIcon,
                                          },
                                          domProps: {
                                            textContent: _vm._s(item.icon),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _vm._v(" "),
                          _vm._l(item.items, function (subItem, subIndex) {
                            return _c(
                              "v-list-item",
                              {
                                key: subIndex,
                                attrs: {
                                  to: subItem.to,
                                  target: subItem.target,
                                  exact: "",
                                },
                              },
                              [
                                _c(
                                  "v-list-item-icon",
                                  [
                                    _c("v-icon", {
                                      attrs: {
                                        size: "20",
                                        color: item.colorIcon,
                                      },
                                      domProps: {
                                        textContent: _vm._s(subItem.icon),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("v-list-item-title", {
                                  staticClass: "caption",
                                  domProps: {
                                    textContent: _vm._s(subItem.title),
                                  },
                                }),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      ),
                    ]
                  : _c("v-list-group", {
                      attrs: {
                        "no-action": "",
                        "sub-group": "",
                        "prepend-icon": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function () {
                              return [
                                _c(
                                  "v-list-item",
                                  {
                                    staticClass: "pl-0",
                                    attrs: {
                                      exact: "",
                                      to: item.to,
                                      target: item.target,
                                    },
                                  },
                                  [
                                    _c(
                                      "v-list-item-icon",
                                      [
                                        _c("v-icon", {
                                          attrs: {
                                            size: "20",
                                            color: item.colorIcon,
                                          },
                                          domProps: {
                                            textContent: _vm._s(item.icon),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("v-list-item-title", {
                                      staticClass: "caption",
                                      domProps: {
                                        textContent: _vm._s(item.title),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    }),
              ],
              2
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }