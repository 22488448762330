<template>
  <v-app id="inspire">
    <HeaderAdmin />
    <DrawerAdmin />
    <v-main>
      <div>
        <nuxt />
      </div>
    </v-main>
    <Loading :options="optDialogLoading" />
    <SocketConnectionError />
  </v-app>
</template>

<script>
import Loading from "~/components/Dialogs/Loading"
import HeaderAdmin from "~/components/HeaderAdmin"
import DrawerAdmin from "~/components/DrawerAdmin"
import SocketConnectionError from "~/components/Socket/ConnectionError"

export default {
  name: `admin`,
  components: {
    HeaderAdmin,
    DrawerAdmin,
    Loading,
    SocketConnectionError
  },
  data: () => ({
    optDialogLoading: {
      persistent: true,
      maxWidth: `290`,
      contentClass: `none-boxshadow`,
      dark: true
    }
  }),
  created () {
    // ========= START: Creando instancia del socket =========
    const appProject = process.env.VUE_APP_app_project
    const tokenAppProject = process.env.VUE_APP_token_app_project
    const credentials = { 'app-project': appProject, 'token-app-project': tokenAppProject }

    this.$root.mySocket = this.$nuxtSocket({
      name: `home`,
      channel: `/${process.env.VUE_APP_config_namespace_socket}`,
      persist: true,
      extraHeaders: credentials,
            path:"/dashboard-service/socket.io",
      reconnectionAttempts: process.env.VUE_APP_reconnection_counter
    })
    
    this.$root.mySocket.on(`connect`, () => {
      console.log(`Connected socket server => `, this.$root.mySocket.connected)
    })

    // Evento cuando el cliente se desconecta del socket. Se ejecuta una sola vez
    this.$root.mySocket.on(`disconnect`, message => {
      console.info(`disconnect message => `, message)
    })

    // Evento que se ejecuta cuando el cliente intenta conectarse al servidor,
    // se ejecuta hasta que la cantidad de reintentos que se le ha establecido
    this.$root.mySocket.on(`connect_error`, message => {
      console.error(`connect_error message => `, message)
      this.$store.commit(`socket/SET_SHOW_ERROR_CONNECTION`, true)
    })
    // ========= END =========
  },
}
</script>
