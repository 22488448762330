import Vue from 'vue'

export const state = () => ({
  dataTable: {},
  loading: false,
  search: ``,
  switchState: true,
  form: false,
  showButtonAction: 0
})

export const mutations = {
  SET_FORM_MAINTENANCE (state, payload) {
    state.form = payload
  },
  SET_FORM_MAINTENANCE_ACTIONS (state, payload) {
    state.showButtonAction = payload
  },
  SET_DATA_TABLE (state, payload) {
    state.dataTable = payload.data
  },
  SET_DATA_TABLE_HEADERS (state, payload) {
    Vue.set(state.dataTable, `headers`, payload.headers)
  },
  SET_DATA_TABLE_ITEMS (state, payload) {
    Vue.set(state.dataTable, `items`, payload.items)
  },
  SET_LOADING (state, payload) {
    state.loading = payload.loading
  },
  SET_TABLE_ASSING_USER (state, payload) {
    state.tableAssingUser = payload.data
  },
  SET_TABLE_ASSING_USER_HEADERS (state, payload) {
    state.tableAssingUser.headers = payload.headers
  },
  SET_TABLE_SEARCH (state, payload) {
    state.search = payload
  },
  SET_TABLE_SWITCH_STATE (state, payload) {
    state.switchState = payload
  }
}

export const actions = {}

