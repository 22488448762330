const { notifications } = require(`~/lib/alerts`)

/**
 * Esta función sirve para controlar los errores que ingresan al catch de axios
 * @param {object} e información devuelta por el catch de axios
 */
export const handleAxiosError = e => {
  const message = {}
  if (!e.response) {
    message.title = `Error de conectividad`
    message.message = `Lo sentimos! No pudimos comunicarnos con los servicios. Intentar nuevamente`
  } else {
    const { status } = e.response
    switch (status) {
      case 400:
        message.title = `Error 400`
        message.message = `Hubo un problema, 400`
        console.info(`Errror 400`)
        break
      case 401:
        message.title = `Error 401`
        message.message = `Hubo un problema, 401`
        console.info(`Errrrrrrrrrrro 401`)
        break
      case 403:
        message.title = `Error 403`
        message.message = `Hubo un problema, 403`
        console.info(`Errrrrrrrrrrro 403`)
        break
      case 404:
        message.title = `Error 404`
        message.message = `Hubo un problema, 404`
        console.info(`Errrrrrrrrrrro 404`)
      case 500:
        message.title = `Error 500`
        message.message = `Hubo un problema, 500`
        console.info(`Errrrrrrrrrrro 500`)
        break
      default:
        message.title = `Error default`
        message.message = `Hubo un problema, default`
        console.info(`Errrrrrrrrrrro default`)
        break
    }
  }

  /**
   * notifications: Esta función sirve para mostrar una alerta en forma de modal
   * @param {string} title título de la alerta (se ob tiene del switch case)
   * @param {string} text texto para mostrar en la parte inferior del título (se obtiene del switch case)
   * @param {string} type tipo de notificación (warning, error, success, info, and question)
   * @param {boolean} showButton para mostrar boton de confirmación
   * @param {int} time tiempo de duración para mostrar la alerta
   */
  const title = message.title, text = message.message, type = `warning`, showButton = true, time = 10000
  notifications(title, text, type, showButton, time)
}

/**
 * Esta función sirve para mostrar una alerta en forma de snackbar (Componente de Vuetify)
 * @param {object} error objeto que contiene el mensaje a mostrar en el front
 */
export const handleSuccessError = error => {
  const { code, message } = error
  
  window.$nuxt.$store.commit(`dialog/SET_DIALOG`, { show: false })
  window.$nuxt.$store.commit(`snackbar/SET_TEXT_SNACK`, message)
  window.$nuxt.$store.commit(`snackbar/SET_CODE_SNACK`, code)
  window.$nuxt.$store.commit(`snackbar/SET_SHOW_CODE`, true)
  window.$nuxt.$store.commit(`snackbar/SET_COLOR_SNACK`, `orange`)
  window.$nuxt.$store.commit(`snackbar/SET_TIMEOUT_SNACK`, 5000)
  window.$nuxt.$store.commit(`snackbar/SET_SHOW_SNACK`, true)
}

/**
 * Esta función sirve para controlar los errores que ingresan al catch desde el servicio de sockets
 * @param {object} e información devuelta por el catch de axios
 */
export const handleSocketError = e => {
  const message = {}
  const { code } = e
  switch (code) {
    case `ECONNREFUSED`:
      message.title = `Problema de conexión`
      message.message = `Hubo un problema de conexión con el servicio de base de datos`
      console.info(`Error ECONNREFUSED`)
      break
    default:
      message.title = `Problema en la base de datos`
      message.message = `Hubo un problema en las consultas hacia la base de datos`
      console.info(`Errrrrrrrrrrro default`)
      break
  }

  /**
   * notifications: Esta función sirve para mostrar una alerta en forma de modal
   * @param {string} title título de la alerta (se ob tiene del switch case)
   * @param {string} text texto para mostrar en la parte inferior del título (se obtiene del switch case)
   * @param {string} type tipo de notificación (warning, error, success, info, and question)
   * @param {boolean} showButton para mostrar boton de confirmación
   * @param {int} time tiempo de duración para mostrar la alerta
   */
  const title = message.title, text = message.message, type = `warning`, showButton = true, time = 10000
  notifications(title, text, type, showButton, time)
}
