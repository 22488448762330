export const state = () => ({
  graphics: {
    items: [],
    options: {
      chart: {
        width: 380,
        type: `pie`
      },
      colors: [`#da1315`, `#febd15`, `#09a70c`],
      labels: [`Detractores`, `Neutrales`, `Promotores`],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {width: 200},
          legend: {position: `right`}
        }
      }]
    }
  },
  channel: [`1`, `2`, `3`, `4`]
})

export const mutations = {
  SET_GRAPHIC_PERCENTAGE (state, payload) {
    state.graphics.items = payload
  },
  SET_CHANNEL_GRAPHIC_NPS (state, payload) {
    state.channel = payload
  }
}

export const actions = {
  getDataGraphicsNps ({ state, dispatch, rootState }, params) {
    const { startDate, endDate } = rootState.filters.dates
    const channel = state.channel.length > 0 ? state.channel : [`1`, `2`, `3`, `4`]
    params = { ...params, startDate, endDate, channel }

    dispatch(`socket/emitEvent`, { eventName: `in:total_valoration`, params }, { root: true })
  },
  getDataTotalSurvey ({ state, dispatch, rootState }, params) {
    const { startDate, endDate } = rootState.filters.dates
    const channel = state.channel.length > 0 ? state.channel : [`1`, `2`, `3`, `4`]
    params = { ...params, startDate, endDate, channel }

    dispatch(`socket/emitEvent`, { eventName: `in:total_survey`, params }, { root: true })
  },
  getDataTotalAnswersSurvey ({ state, dispatch, rootState }, params) {
    const { startDate, endDate } = rootState.filters.dates
    const channel = state.channel.length > 0 ? state.channel : [`1`, `2`, `3`, `4`]
    params = { ...params, startDate, endDate, channel }

    dispatch(`socket/emitEvent`, { eventName: `in:total_answer_survey`, params }, { root: true })
  },
  getShippingAndResponseRate ({ state, dispatch, rootState }, params) {
    const { startDate, endDate } = rootState.filters.dates
    const channel = state.channel.length > 0 ? state.channel : [`1`, `2`, `3`, `4`]
    params = { ...params, startDate, endDate, channel }

    dispatch(`socket/emitEvent`, { eventName: `in:shipping_response_rate`, params }, { root: true })
  }
}

