export default {
  computed: {
    colorPrimary () {
      return `${this.$store.state.app.colorPrimary}`
    },
    apiServiceDataBase () {
      return this.$store.state.environment.apiServiceDataBase
    },
    slaAttended () {
      return this.$store.state.environment.slaAttended
    },
    slaAbandoned () {
      return this.$store.state.environment.slaAbandoned
    }
  },
  methods: {
    zeroPad (value, order) {
      // Esta función antepone ceros a un número y lo devuelve como un string
      const val_str = ``+value
      return (``+(10**order)).substring(val_str.length)+val_str
    },
    // Agrupar array por key
    groupByKey: (array, key) => {
      return array.reduce((result, currentValue) => {
        (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue)
        return result
      }, {})
    },
    inRange: (number, min, max) => number >= min && number < max,
    randomId: () => `_` + Math.random().toString(36).substr(2, 9)
  }
}

