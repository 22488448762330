export const state = () => ({
  socket: {},
  showErrorConnection: false
})

export const mutations = {
  SET_SOCKET (state, payload) {
    state.socket = payload
  },
  SET_SHOW_ERROR_CONNECTION (state, payload) {
    state.showErrorConnection = payload
  }
}

export const actions = {
  emitEvent (_, { eventName, params }) {
    const socket = window.$nuxt.$root.mySocket
    socket.emit(eventName, params)
  },
  onEvent (_, { eventName, callback }) {
    const socket = window.$nuxt.$root.mySocket
    socket.on(eventName, response => callback(response))
  }
}

export const getters = {getSocket: state => state.socket}
