<template>
<v-main>
  <v-app>
    <client-only>
      <nuxt />
    </client-only>
  </v-app>
    <SocketConnectionError />
</v-main>
</template>

<script>
import SocketConnectionError from "~/components/Socket/ConnectionError"

export default {
  name: `default`,
  components: {SocketConnectionError},
  created () {
    // ========= START: Creando instancia del socket =========
    if (process.client) {
      const appProject = process.env.VUE_APP_app_project
      const tokenAppProject = process.env.VUE_APP_token_app_project
      const credentials = { 'app-project': appProject, 'token-app-project': tokenAppProject }
  
      this.$root.mySocket = this.$nuxtSocket({
        name: `home`,
        channel: `/${process.env.VUE_APP_config_namespace_socket}`,
        persist: true,
        path:"/dashboard-service/socket.io",
        extraHeaders: credentials,
        reconnectionAttempts: process.env.VUE_APP_reconnection_counter
      })
      
      this.$root.mySocket.on(`connect`, () => {
        console.log(`Connected socket server => `, this.$root.mySocket.connected)
      })
  
      // Evento cuando el cliente se desconecta del socket. Se ejecuta una sola vez
      this.$root.mySocket.on(`disconnect`, message => {
        console.info(`disconnect message => `, message)
      })
  
      // Evento que se ejecuta cuando el cliente intenta conectarse al servidor,
      // se ejecuta hasta que la cantidad de reintentos que se le ha establecido
      this.$root.mySocket.on(`connect_error`, message => {
        console.error(`connect_error message => `, message)
        this.$store.commit(`socket/SET_SHOW_ERROR_CONNECTION`, true)
      })


    }
    // ========= END =========
  },
}
</script>
