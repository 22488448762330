export const state = () => ({ settings: null })

export const mutations = {
  SET_SETTINGS (state, payload) {
    state.settings = payload
  }
}

export const actions = {
  // Obtener las configuraciones globales
  listSettings ({ commit, dispatch }) {
    dispatch(`socket/emitEvent`, { eventName: `in:load_settings` }, { root: true })
    dispatch(`socket/onEvent`, {
      eventName: `out:load_settings`,
      callback: response => {
        const { data } = response

        commit(`SET_SETTINGS`, data[0])
      }
    }, { root: true })
  }
}

export const getters = { getSettings: state => state.settings }
