// ES6 Modules or TypeScript
const Swal = require(`sweetalert2`)

/**
 * Sirve para mostrar una notificación usando sweetalert
 * @param {string} title que sirve para mostrar como título de la alerta
 * @param {string} text que sirve para mostrar mas detalle de la acción realizada
 * @param {string} type tipo de alerta a mostrar: success o error, solo se está usando esos dos
 * @param {boolean} showButton sirve para mostrar boton de confirmación
 * @param {number} timer sirve para darle un tiempo(milisegundos) de visibilidad a la alerta
 */
export const notifications = (title, text, type, showButton, timer) => {
  Swal.fire({
    type: type,
    title: title,
    text: text,
    showConfirmButton: showButton,
    timer: timer
  })
}
