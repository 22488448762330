/**
 * Tienda de datos para toda la aplicación y se puede acceder a ella de cualquier parte de los componentes, mixins
 * Acá se manejará el estado para el HEADER de la aplicación
 */
/**
 * Declaramos las propiedades de los estados a usar para este módulo
 */
export const state = () => ({
  drawerTools: false,
  drawerToolsOptions: {
    title: `States`,
    icon: `fas fa-shield-alt`,
    items: []
  },
  primaryDrawer: {
    model: true,
    mini: false,
    miniview: false
  }
})

/**
 * Tiene la funcionalidad de modificar los estado, ya que VUEX no permite modificar directamente los estados
 * @param {*} state hace referencia a las propiedades declaradas en el estado
 * @param {*} payload hace referencia al valor enviado mediante los commits para que dicho valor modifique a la propiedad.
 */
export const mutations = {
  TOGGLE_DRAWER_VISIBILITY: (state, payload) => {
    state.primaryDrawer.model = payload !== undefined ? payload : !state.primaryDrawer.show
  },
  TOGGLE_DRAWER_MINI: (state, payload) => {
    state.primaryDrawer.mini = payload !== undefined ? payload : !state.primaryDrawer.mini
  },
  TOGGLE_MINIVARIENT_MODE: (state, payload) => {
    state.primaryDrawer.miniview = payload !== undefined ? payload : !state.primaryDrawer.miniview
  },

  SET_DRAWER_TOOLS (state, payload) {
    state.drawerTools = payload.drawerTools
  },
  SET_DRAWER_TOOLS_OPTIONS (state, payload) {
    state.drawerToolsOptions.title = payload.title
    state.drawerToolsOptions.icon = payload.icon
    state.drawerToolsOptions.items = payload.items
  }
}

export const actions = {
  toggleDrawer: ({ commit }, payload) => {
    commit(`TOGGLE_DRAWER_VISIBILITY`, payload)
  },
  toggleMiniVarient: ({ commit }, payload) => {
    commit(`TOGGLE_DRAWER_MINI`, payload)
  },
  toggleMiniVariantMode: ({ commit }, payload) => {
    commit(`TOGGLE_MINIVARIENT_MODE`, payload)
  }
}
