var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list",
    { attrs: { shaped: "" } },
    _vm._l(_vm.items, function (item) {
      return _c(
        "v-list-item",
        {
          key: item.title,
          attrs: { to: item.to, exact: "", target: item.target },
        },
        [
          _c(
            "v-list-item-icon",
            [
              _c("v-icon", {
                attrs: { size: "20", color: item.colorIcon },
                domProps: { textContent: _vm._s(item.icon) },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-list-item-title", {
            staticClass: "caption",
            domProps: { textContent: _vm._s(item.title) },
          }),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }