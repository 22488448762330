var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        persistent: "",
        "max-width": "290",
        "content-class": "none-boxshadow",
        dark: "",
      },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "vcard-dloading" },
        [
          _c(
            "v-layout",
            { attrs: { column: "", "justify-center": "", "align-center": "" } },
            [
              _c(
                "v-flex",
                {
                  attrs: { xs12: "", sm12: "", md12: "", lg12: "", "mb-5": "" },
                },
                [
                  _c("dot-loader", {
                    staticClass: "dialog-loader-api",
                    attrs: { loading: _vm.loading },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-flex",
                {
                  staticClass: "text-center",
                  attrs: { xs12: "", sm12: "", md12: "", lg12: "" },
                },
                [
                  _c(
                    "label",
                    { staticClass: "headline white--text font-weight-bold" },
                    [_vm._v("Por favor, espera un momento…")]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }