var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    [
      _c("v-app", [_c("client-only", [_c("nuxt")], 1)], 1),
      _vm._v(" "),
      _c("SocketConnectionError"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }