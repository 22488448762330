var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app-bar",
    { attrs: { app: "", "clipped-left": "", color: "white" } },
    [
      _c(
        "span",
        { staticClass: "mr-5" },
        [_c("v-img", { attrs: { src: "/media/logo.png" } })],
        1
      ),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          staticClass: "ml-3",
          attrs: { icon: "", light: "" },
          on: {
            click: function ($event) {
              _vm.primaryDrawer = !_vm.primaryDrawer
            },
          },
        },
        [
          _vm.primaryDrawer
            ? _c(
                "v-tooltip",
                {
                  staticClass: "color-primary",
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on }) {
                          return [
                            _c(
                              "v-icon",
                              _vm._g({ attrs: { size: "20" } }, on),
                              [_vm._v("fas fa-bars")]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    42871859
                  ),
                },
                [_vm._v(" "), _c("span", [_vm._v(_vm._s(_vm.$t("collapse")))])]
              )
            : _c(
                "v-tooltip",
                {
                  staticClass: "color-primary",
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on }) {
                        return [
                          _c("v-icon", _vm._g({ attrs: { size: "20" } }, on), [
                            _vm._v("fas fa-bars"),
                          ]),
                        ]
                      },
                    },
                  ]),
                },
                [_vm._v(" "), _c("span", [_vm._v(_vm._s(_vm.$t("open")))])]
              ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          attrs: { icon: "", light: "" },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.toggleMiniVariantMode.apply(null, arguments)
            },
          },
        },
        [
          _vm.primaryDrawerMini
            ? _c(
                "v-tooltip",
                {
                  staticClass: "color-primary",
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on }) {
                          return [
                            _c(
                              "v-icon",
                              _vm._g({ attrs: { size: "20" } }, on),
                              [_vm._v("fas fa-angle-right")]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1124456765
                  ),
                },
                [_vm._v(" "), _c("span", [_vm._v(_vm._s(_vm.$t("extend")))])]
              )
            : _c(
                "v-tooltip",
                {
                  staticClass: "color-primary",
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on }) {
                        return [
                          _c("v-icon", _vm._g({ attrs: { size: "20" } }, on), [
                            _vm._v("fas fa-angle-left"),
                          ]),
                        ]
                      },
                    },
                  ]),
                },
                [_vm._v(" "), _c("span", [_vm._v("Mini")])]
              ),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-spacer"),
      _vm._v(" "),
      _c(
        "v-toolbar-items",
        [
          _c(
            "v-menu",
            {
              attrs: { left: "", bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on }) {
                    return [
                      _c(
                        "v-btn",
                        _vm._g({ attrs: { icon: "" } }, on),
                        [
                          _c(
                            "v-avatar",
                            { attrs: { size: "30", color: "transparent" } },
                            [_c("v-img", { attrs: { src: _vm.imgSrc } })],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _vm._v(" "),
              _c(
                "v-card",
                {
                  staticClass: "mx-auto",
                  attrs: { "max-width": "300", tile: "" },
                },
                [
                  _c(
                    "v-list",
                    { attrs: { rounded: "" } },
                    [
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-avatar",
                            [_c("v-img", { attrs: { src: _vm.imgSrc } })],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(_vm._s(_vm.user && _vm.user.fullName)),
                              ]),
                              _vm._v(" "),
                              _c("v-list-item-subtitle", [
                                _vm._v("Supervisor Apropo"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c(
                    "v-list",
                    [
                      _c(
                        "v-list-item",
                        { on: { click: _vm.logout } },
                        [
                          _c(
                            "v-list-item-avatar",
                            [
                              _c("v-icon", { attrs: { size: "22" } }, [
                                _vm._v("fas fa-power-off"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item-content",
                            [_c("v-list-item-title", [_vm._v("Logout")])],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }