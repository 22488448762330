<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="290"
    content-class="none-boxshadow"
    dark
  >
    <v-card class="vcard-dloading">
      <v-layout
        column
        justify-center
        align-center
      >
        <v-flex
          xs12
          sm12
          md12
          lg12
          mb-5
        >
          <dot-loader
            :loading="loading"
            class="dialog-loader-api"
          />
        </v-flex>
        <v-flex
          xs12
          sm12
          md12
          lg12
          class="text-center"
        >
          <label class="headline white--text font-weight-bold">Por favor, espera un momento…</label>
        </v-flex>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>
import DotLoader from 'vue-spinner/src/DotLoader.vue'
export default {
  name: `loading`,
  components: { DotLoader },
  computed: {
    loading: {
      get () {
        return this.$store.state.dialog.show
      },
      set (newVal) {
        this.$store.commit(`dialog/SET_DIALOG`, { show: newVal })
      }
    },
    dialog: {
      get () {
        return this.$store.state.dialog.show
      },
      set (newVal) {
        this.$store.commit(`dialog/SET_DIALOG`, { show: newVal })
      }
    }
  }
}
</script>
