export const state = () => ({
  show: false,
  showDrawerStatusQueues: true
})

export const mutations = {
  SET_DIALOG (state, payload) {
    state.show = payload.show
  },
  SET_DRAWER_STATUS_QUEUES (state, payload) {
    state.showDrawerStatusQueues = payload
  }
}
