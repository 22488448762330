export default {
  computed: {
    toolbarColor: {
      get () {
        return this.$store.state.app.toolbarColor
      },
      set (newVal) {
        this.$store.commit(`app/SET_TOOLBAR_COLOR`, { toolbarColor: newVal })
      }
    },
    primaryDrawer: {
      get () {
        return this.$store.state.header.primaryDrawer.model
      },
      set (value) {
        this.$store.commit(`header/TOGGLE_DRAWER_VISIBILITY`, value)
      }
    },
    primaryDrawerMini: {
      get () {
        return this.$store.state.header.primaryDrawer.mini
      },
      set (value) {
        this.$store.commit(`header/TOGGLE_DRAWER_MINI`, value)
      }
    },
    primaryDrawerMiniMode: {
      get () {
        return this.$store.state.header.primaryDrawer.miniview
      },
      set (value) {
        this.$store.commit(`header/TOGGLE_MINIVARIENT_MODE`, value)
      }
    }
  }
}
