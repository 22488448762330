import Vue from 'vue';
import VueSweetalert2 from 'vue-sweetalert2';

Vue.use(VueSweetalert2, {
  "confirmButtonColor": "rgb(48, 133, 214)",
  "cancelButtonColor": "rgb(221, 51, 51)"
});
export default ({}, inject) => {
  inject('swal', Vue.swal)
}
