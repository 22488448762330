var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-navigation-drawer",
    {
      attrs: {
        id: "app-drawer",
        app: "",
        overflow: "",
        width: "256",
        clipped: "",
      },
      scopedSlots: _vm._u([
        {
          key: "prepend",
          fn: function () {
            return [
              _c(
                "v-list-item",
                { staticClass: "mt-4 mb-4", attrs: { "two-line": "" } },
                [
                  _c(
                    "v-list-item-avatar",
                    [
                      _c("v-img", {
                        attrs: {
                          src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSsgLMiDAw6mY801rwwaC3DTjjIDDaOEPEJ7Q&usqp=CAU",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [
                        _vm._v(_vm._s(_vm.user && _vm.user.fullName)),
                      ]),
                      _vm._v(" "),
                      _c("v-list-item-subtitle", [_vm._v("Supervisor Apropo")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "simplebar",
        {
          staticClass: "height-100",
          attrs: { "data-simplebar-auto-hide": "true" },
        },
        [
          _c("v-divider"),
          _vm._v(" "),
          _c("MenuItems", { attrs: { items: _vm.itemsHome } }),
          _vm._v(" "),
          _c("MenuSubItems", {
            attrs: {
              items: _vm.itemsCalls,
              title: _vm.$t(`drawerAdmin.dashboards.calls.title`),
              icon: "fas fa-phone-volume",
              colorIcon: "rgb(240, 56, 0)",
            },
          }),
          _vm._v(" "),
          _c("MenuSubItems", {
            attrs: {
              items: _vm.itemsChats,
              title: _vm.$t(`drawerAdmin.dashboards.chats.title`),
              icon: "fas fa-comment-dots",
              colorIcon: "#F3705A",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }