export const state = () => ({
  dashboards: {
    categoryBySex: {
      series: [],
      options: {}
    },
    categoryByChannel: {
      series: [],
      options: {}
    },
    sexByQueues: {
      series: [],
      options: {}
    },
    channelBySex: {
      series: [],
      options: {}
    },
    rangeAgeBySex: {
      series: [],
      options: {}
    },
    rangeAgeByChannel: {
      series: [],
      options: {}
    },
    donutsByCategories: {
      series: [],
      options: {}
    },
    donutsBySex: {
      series: [],
      options: {}
    },
    donutsByChannel: {
      series: [],
      options: {}
    }
  }
})

export const mutations = {
  SET_CATEGORY_BY_SEX (state, payload) {
    state.dashboards.categoryBySex = Object.assign({}, state.dashboards.categoryBySex, payload)
  },
  SET_CATEGORY_BY_CHANNEL (state, payload) {
    state.dashboards.categoryByChannel = Object.assign({}, state.dashboards.categoryByChannel, payload)
  },
  SET_SEX_BY_QUEUES (state, payload) {
    state.dashboards.sexByQueues = Object.assign({}, state.dashboards.sexByQueues, payload)
  },
  SET_CHANNELS_BY_SEX (state, payload) {
    state.dashboards.channelBySex = Object.assign({}, state.dashboards.channelBySex, payload)
  },
  SET_RANGE_AGE_BY_SEX (state, payload) {
    state.dashboards.rangeAgeBySex = Object.assign({}, state.dashboards.rangeAgeBySex, payload)
  },
  SET_RANGE_AGE_BY_CHANNEL (state, payload) {
    state.dashboards.rangeAgeByChannel = Object.assign({}, state.dashboards.rangeAgeByChannel, payload)
  },
  SET_DONUTS_BY_CATEGORIES (state, payload) {
    state.dashboards.donutsByCategories = Object.assign({}, state.dashboards.donutsByCategories, payload)
  },
  SET_DONUTS_BY_SEX (state, payload) {
    state.dashboards.donutsBySex = Object.assign({}, state.dashboards.donutsBySex, payload)
  },
  SET_DONUTS_BY_CHANNEL (state, payload) {
    state.dashboards.donutsByChannel = Object.assign({}, state.dashboards.donutsByChannel, payload)
  }
}

export const actions = {
  loadCategoryBySex ({ commit, dispatch, rootState }, payload) {
    const { dates } = rootState.filters
    const { startDate, endDate } = dates
    const params = { ...payload, startDate, endDate }

    dispatch(`socket/emitEvent`, { eventName: `in:dashboard_general_category_by_sex`, params }, { root: true })
    dispatch(`socket/onEvent`, {
      eventName: `out:dashboard_general_category_by_sex`,
      callback: response => {
        commit(`SET_CATEGORY_BY_SEX`, response)
        commit(`dialog/SET_DIALOG`, { show: false }, { root: true })
      }
    }, { root: true })
  },
  loadCategoryByChannel ({ commit, dispatch, rootState }, payload) {
    const { dates } = rootState.filters
    const { startDate, endDate } = dates
    const params = { ...payload, startDate, endDate }

    dispatch(`socket/emitEvent`, { eventName: `in:dashboard_general_category_by_channel`, params }, { root: true })
    dispatch(`socket/onEvent`, {
      eventName: `out:dashboard_general_category_by_channel`,
      callback: response => {
        commit(`SET_CATEGORY_BY_CHANNEL`, response)
        commit(`dialog/SET_DIALOG`, { show: false }, { root: true })
      }
    }, { root: true })
  },
  loadSexByQueues ({ commit, dispatch, rootState }, payload) {
    const { dates } = rootState.filters
    const { startDate, endDate } = dates
    const params = { ...payload, startDate, endDate }

    dispatch(`socket/emitEvent`, { eventName: `in:dashboard_general_sex_by_queues`, params }, { root: true })
    dispatch(`socket/onEvent`, {
      eventName: `out:dashboard_general_sex_by_queues`,
      callback: response => {
        commit(`SET_SEX_BY_QUEUES`, response)
        commit(`dialog/SET_DIALOG`, { show: false }, { root: true })
      }
    }, { root: true })
  },
  loadChannelsBySex ({ commit, dispatch, rootState }, payload) {
    const { dates } = rootState.filters
    const { startDate, endDate } = dates
    const params = { ...payload, startDate, endDate }

    dispatch(`socket/emitEvent`, { eventName: `in:dashboard_general_channels_by_sex`, params }, { root: true })
    dispatch(`socket/onEvent`, {
      eventName: `out:dashboard_general_channels_by_sex`,
      callback: response => {
        commit(`SET_CHANNELS_BY_SEX`, response)
        commit(`dialog/SET_DIALOG`, { show: false }, { root: true })
      }
    }, { root: true })
  },
  loadRangeAgeBySex ({ commit, dispatch, rootState }, payload) {
    const { dates } = rootState.filters
    const { startDate, endDate } = dates
    const params = { ...payload, startDate, endDate }

    dispatch(`socket/emitEvent`, { eventName: `in:dashboard_general_range_age_by_sex`, params }, { root: true })
    dispatch(`socket/onEvent`, {
      eventName: `out:dashboard_general_range_age_by_sex`,
      callback: response => {
        commit(`SET_RANGE_AGE_BY_SEX`, response)
        commit(`dialog/SET_DIALOG`, { show: false }, { root: true })
      }
    }, { root: true })
  },
  loadRangeAgeByChannel ({ commit, dispatch, rootState }, payload) {
    const { dates } = rootState.filters
    const { startDate, endDate } = dates
    const params = { ...payload, startDate, endDate }

    dispatch(`socket/emitEvent`, { eventName: `in:dashboard_general_range_age_by_channel`, params }, { root: true })
    dispatch(`socket/onEvent`, {
      eventName: `out:dashboard_general_range_age_by_channel`,
      callback: response => {
        commit(`SET_RANGE_AGE_BY_CHANNEL`, response)
        commit(`dialog/SET_DIALOG`, { show: false }, { root: true })
      }
    }, { root: true })
  },
  loadByCategories ({ commit, dispatch, rootState }, payload) {
    const { dates } = rootState.filters
    const { startDate, endDate } = dates
    const params = { ...payload, startDate, endDate }

    dispatch(`socket/emitEvent`, { eventName: `in:dashboard_general_by_categories`, params }, { root: true })
    dispatch(`socket/onEvent`, {
      eventName: `out:dashboard_general_by_categories`,
      callback: response => {
        commit(`SET_DONUTS_BY_CATEGORIES`, response)
        commit(`dialog/SET_DIALOG`, { show: false }, { root: true })
      }
    }, { root: true })
  },
  loadBySex ({ commit, dispatch, rootState }, payload) {
    const { dates } = rootState.filters
    const { startDate, endDate } = dates
    const params = { ...payload, startDate, endDate }

    dispatch(`socket/emitEvent`, { eventName: `in:dashboard_general_by_sex`, params }, { root: true })
    dispatch(`socket/onEvent`, {
      eventName: `out:dashboard_general_by_sex`,
      callback: response => {
        commit(`SET_DONUTS_BY_SEX`, response)
        commit(`dialog/SET_DIALOG`, { show: false }, { root: true })
      }
    }, { root: true })
  },
  loadByChannel ({ commit, dispatch, rootState }, payload) {
    const { dates } = rootState.filters
    const { startDate, endDate } = dates
    const params = { ...payload, startDate, endDate }

    dispatch(`socket/emitEvent`, { eventName: `in:dashboard_general_by_channel`, params }, { root: true })
    dispatch(`socket/onEvent`, {
      eventName: `out:dashboard_general_by_channel`,
      callback: response => {
        commit(`SET_DONUTS_BY_CHANNEL`, response)
        commit(`dialog/SET_DIALOG`, { show: false }, { root: true })
      }
    }, { root: true })
  }
}

export const getters = {}
