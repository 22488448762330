import axios from 'axios'
import { handleAxiosError, handleSuccessError } from '~/lib/handleError'

const getHeaders = () => {
  return {
    headers: {
      'Accept': `application/json`,
      'Content-Type': `application/json`
    }
  }
}

/**
 * Función reusable para las peticiones HTTP GET
 * @param {string} api endpoint al cual sera solicitado
 * @param {string} url ruta complementaria del endpoint
 * @param {object} params objeto que contiene los parámetros necesario para el servicio solicitado
 */
export const get = ({api, url, params}) => {
  return new Promise(resolve => {
    axios.get(`${api}${url}`, { params: params }, getHeaders()).then(response => {
      if (response.data.success) {
        resolve(response.data)
      } else {
        handleSuccessError(response.data.error)
      }
    }).catch(error => handleAxiosError(error))
  })
}

/**
 * Función reusable para las peticiones HTTP POST
 * @param {string} api endpoint al cual sera solicitado
 * @param {string} url ruta complementaria del endpoint
 * @param {object} params objeto que contiene los parámetros necesario para el servicio solicitado
 */
export const post = ({api, url, params}) => {
  return new Promise(resolve => {
    axios.post(`${api}${url}`, params, getHeaders()).then(response => {
      if (response.data.success) {
        resolve(response.data)
      } else {
        handleSuccessError(response.data.error)
      }
    }).catch(error => handleAxiosError(error))
  })
}

/**
 * Función reusable para las peticiones HTTP DELETE
 * @param {string} api endpoint al cual sera solicitado
 * @param {string} url ruta complementaria del endpoint
 * @param {object} params objeto que contiene los parámetros necesario para el servicio solicitado
 */
export const deleted = ({api, url, params}) => {
  return new Promise(resolve => {
    axios.delete(`${api}${url}`, { data: params }, getHeaders()).then(response => {
      if (response.data.success) {
        resolve(response.data)
      } else {
        handleSuccessError(response.data.error)
      }
    }).catch(error => handleAxiosError(error))
  })
}

/**
 * Función reusable para las peticiones HTTP PUT
 * @param {string} api endpoint al cual sera solicitado
 * @param {string} url ruta complementaria del endpoint
 * @param {object} params objeto que contiene los parámetros necesario para el servicio solicitado
 */
export const updated = ({api, url, params}) => {
  return new Promise(resolve => {
    axios.put(`${api}${url}`, params, getHeaders()).then(response => {
      if (response.data.success) {
        resolve(response.data)
      } else {
        handleSuccessError(response.data.error)
      }
    }).catch(error => handleAxiosError(error))
  })
}

/**
 * Función que sólo sirve para la subida de archivos
 * @param {string} api endpoint al cual sera solicitado
 * @param {string} url ruta complementaria del endpoint
 * @param {object} params objeto que contiene los parámetros necesario para el servicio solicitado
 * @param {object} headers objeto que contiene tipo de contenido que se enviará al servidor
 */
export const uploadFile = ({api, url, params, headers}) => {
  return new Promise(resolve => {
    axios.post(`${api}${url}`, params, headers).then(response => {
      if (response.data.success) {
        resolve(response.data)
      } else {
        handleSuccessError(response.data.error)
      }
    }).catch(error => handleAxiosError(error))
  })
}
