export const state = () => ({
  dashboards: {
    consolidate: {
      kpiMetrics: [],
      attentionsByDates: {
        series: [],
        options: {}
      },
      attentionsByRangeAge: {
        series: [],
        options: {}
      },
      attentionsByTypeEvent: {
        series: [],
        options: {}
      },
      attentionsByQueue: {
        series: [],
        options: {}
      },
      attentionsBySexAndQueue: {
        series: [],
        options: {}
      }
    }
  },
  kpiMetric: {
    text: `Recibidas`,
    value: `received`
  }
})

export const mutations = {
  SET_CONSOLIDATE_ITEMS_METRICS (state, payload) {
    state.dashboards.consolidate.kpiMetrics = payload
  },
  SET_KPI_METRIC (state, payload) {
    state.kpiMetric = Object.assign({}, state.kpiMetri, payload)
  },
  SET_ATTENTIONS_BY_DATES (state, payload) {
    state.dashboards.consolidate.attentionsByDates = Object.assign({}, state.dashboards.consolidate.attentionsByDates, payload)
  },
  SET_ATTENTIONS_BY_RANGE_AGE (state, payload) {
    state.dashboards.consolidate.attentionsByRangeAge = Object.assign({}, state.dashboards.consolidate.attentionsByRangeAge, payload)
  },
  SET_ATTENTIONS_BY_TYPE_EVENT (state, payload) {
    state.dashboards.consolidate.attentionsByTypeEvent = Object.assign({}, state.dashboards.consolidate.attentionsByTypeEvent, payload)
  },
  SET_ATTENTIONS_BY_QUEUE (state, payload) {
    state.dashboards.consolidate.attentionsByQueue = Object.assign({}, state.dashboards.consolidate.attentionsByQueue, payload)
  },
  SET_ATTENTIONS_BY_SEX_QUEUE (state, payload) {
    state.dashboards.consolidate.attentionsBySexAndQueue = Object.assign({}, state.dashboards.consolidate.attentionsBySexAndQueue, payload)
  }
}

export const actions = {
  loadConsolidateMetrics ({ commit, dispatch, rootState }, payload) {
    const { dates, option } = rootState.filters
    const { startDate, endDate } = dates
    const { slaAttended, slaAbandoned } = rootState.settings.settings
    const params = (payload === null || payload === undefined) ? { startDate, endDate, option } : payload

    params.option = option
    params.slaAbandoned = slaAbandoned
    params.slaAttended = slaAttended

    dispatch(`socket/emitEvent`, { eventName: `in:dashboard_kpi_metrics`, params }, { root: true })
    dispatch(`socket/onEvent`, {
      eventName: `out:dashboard_kpi_metrics`,
      callback: response => {
        const activeKpi = this.$cookies.get(`activeKpi`)

        if (activeKpi) {
          const data = response.map(kpis => {
            const isActive = kpis.value === activeKpi.value ? true : false
            return { ...kpis, isActive }
          })
          commit(`SET_CONSOLIDATE_ITEMS_METRICS`, data)
        } else {
          commit(`SET_CONSOLIDATE_ITEMS_METRICS`, response)
        }

        commit(`dialog/SET_DIALOG`, { show: false }, { root: true })
      }
    }, { root: true })
  },
  loadAttentionsByDates ({ commit, dispatch, rootState }, payload) {
    const { dates, option } = rootState.filters
    const { startDate, endDate } = dates
    const { slaAttended, slaAbandoned } = rootState.settings.settings
    const params = (payload === null || payload === undefined) ? { startDate, endDate, option } : { startDate, endDate, ...payload }

    params.option = option
    params.slaAbandoned = slaAbandoned
    params.slaAttended = slaAttended

    dispatch(`socket/emitEvent`, { eventName: `in:dashboard_attentions_by_dates`, params }, { root: true })
    dispatch(`socket/onEvent`, {
      eventName: `out:dashboard_attentions_by_dates`,
      callback: response => {
        commit(`SET_ATTENTIONS_BY_DATES`, response)
        commit(`dialog/SET_DIALOG`, { show: false }, { root: true })
      }
    }, { root: true })
  },
  loadAttentionsByRangeAge ({ commit, dispatch, rootState }, payload) {
    const { dates } = rootState.filters
    const { startDate, endDate } = dates
    const params = { startDate, endDate, ...payload }

    dispatch(`socket/emitEvent`, { eventName: `in:dashboard_attentions_by_range_age`, params }, { root: true })
    dispatch(`socket/onEvent`, {
      eventName: `out:dashboard_attentions_by_range_age`,
      callback: response => {
        commit(`SET_ATTENTIONS_BY_RANGE_AGE`, response)
        commit(`dialog/SET_DIALOG`, { show: false }, { root: true })
      }
    }, { root: true })
  },
  loadAttentionsByTypeEvent ({ commit, dispatch, rootState }, payload) {
    const { dates } = rootState.filters
    const { startDate, endDate } = dates
    const params = { startDate, endDate, ...payload }

    dispatch(`socket/emitEvent`, { eventName: `in:dashboard_attentions_by_type_event`, params }, { root: true })
    dispatch(`socket/onEvent`, {
      eventName: `out:dashboard_attentions_by_type_event`,
      callback: response => {
        commit(`SET_ATTENTIONS_BY_TYPE_EVENT`, response)
        commit(`dialog/SET_DIALOG`, { show: false }, { root: true })
      }
    }, { root: true })
  },
  loadAttentionsByQueue ({ commit, dispatch, rootState }, payload) {
    const { dates } = rootState.filters
    const { startDate, endDate } = dates
    const params = { startDate, endDate, ...payload }

    dispatch(`socket/emitEvent`, { eventName: `in:dashboard_attentions_by_queues`, params }, { root: true })
    dispatch(`socket/onEvent`, {
      eventName: `out:dashboard_attentions_by_queues`,
      callback: response => {
        commit(`SET_ATTENTIONS_BY_QUEUE`, response)
        commit(`dialog/SET_DIALOG`, { show: false }, { root: true })
      }
    }, { root: true })
  },
  loadAttentionsBySexQueues ({ commit, dispatch, rootState }, payload) {
    const { dates } = rootState.filters
    const { startDate, endDate } = dates
    const params = { startDate, endDate, ...payload }

    dispatch(`socket/emitEvent`, { eventName: `in:dashboard_attentions_by_sex_queues`, params }, { root: true })
    dispatch(`socket/onEvent`, {
      eventName: `out:dashboard_attentions_by_sex_queues`,
      callback: response => {
        commit(`SET_ATTENTIONS_BY_SEX_QUEUE`, response)
        commit(`dialog/SET_DIALOG`, { show: false }, { root: true })
      }
    }, { root: true })
  }
}

export const getters = {
  getItemsKpiMetrics: state => state.dashboards.consolidate.kpiMetrics,
  getKpiMetric: state => state.kpiMetric
}
