export const state = () => ({
  apiServiceDataBase: process.env.VUE_APP_api_bd_services,
  apiServiceSocket: process.env.VUE_APP_dashboard_service,
  configNameSocket: process.env.VUE_APP_config_name_socket,
  configNameSpaceSocket: process.env.VUE_APP_config_namespace_socket,
  reconnectionCounter: process.env.VUE_APP_reconnection_counter,
  msgErrorConnectionSocket: process.env.VUE_APP_msg_error_connection_socket,
  language: process.env.VUE_APP_translation,
  appProject: process.env.VUE_APP_app_project,
  tokenAppProject: process.env.VUE_APP_token_app_project,
  secretKey: process.env.VUE_APP_secret_key
})

export const getters = {
  getReconnectionCounter: state => state.reconnectionCounter,
  getApiServiceSocket: state => state.apiServiceSocket,
  getMsgErrorConnectionSocket: state => state.msgErrorConnectionSocket,
  getConfigNameSocket: state => state.configNameSocket,
  getConfigNameSpaceSocket: state => state.configNameSpaceSocket,
  getAppProject: state => state.appProject,
  getTokenAppProject: state => state.tokenAppProject,
  getSecretKey: state => state.secretKey
}
